// NAVIGATION //////////////////
const navMenuBtn = document.querySelector('#nav-menu-btn');
const navMenu = document.querySelector('.header__nav-menu');

navMenuBtn.addEventListener('click', () => {
    navMenu.classList.toggle('nav--show');
})

navMenu.querySelectorAll('li').forEach(el => {
    el.addEventListener('click', () => {
        scrollIntoView(el.dataset.scrollFrom);
        navMenu.classList.toggle('nav--show');
    })
})

navMenu.addEventListener('mouseleave', () => {
    navMenu.classList.remove('nav--show');
})

function scrollIntoView(tagId) {
    const target = document.querySelector(`a[data-scroll-to="${tagId}"]`)
    target.scrollIntoView({ behavior: "smooth", block: "start" })
}



// MODAL //////////////////////
const menuButton = document.querySelector('#contact-btn');
const modal = document.querySelector('.contact-modal');
const closeButton = modal.querySelector('.icon-cross');

menuButton.addEventListener('click', openModal)

function openModal() {
    modal.style.display = 'block';
}

closeButton.addEventListener('click', () => {
    modal.style.display = 'none';
})

window.addEventListener('click', () => {
    if (event.target.classList[0] === 'contact-modal') {
        modal.style.display = 'none';
    }
    if (
        event.target.classList.contains('snackbar--close') ||
        event.target.parentElement.classList.contains('snackbar--close')
    ) {
        document.querySelectorAll('.ef-contact-snackbar').forEach(el => el.classList.add('ef-snackbar--hidden'));
    }
})

///////////////////////////////

// Contact Forms ////////////////

function generateErrorMessage(message) {
    let reason;
    switch (message) {
        case 'Captcha verification failed':
            reason = 'Verifieringen misslyckades, vänligen försök igen.'
            break;

        default:
            reason = 'Ett okänt fel har inträffat, vänligen försök igen.'
            break;
    }

    const errorMessage = reason;

    return errorMessage;
}

const submitButtons = document.querySelectorAll('.send-btn')
submitButtons.forEach(btn => {
    btn.addEventListener('click', (e) => {
        e.preventDefault();
        if (!validateForm(e.target.form)) {
            return alert('Formuläret ej korrekt ifyllt')
        }

        toggleSubmitButton(btn);
        sendContactFormData(e.target.form)
            .then(() => {
                toggleSubmitButton(btn);
            })
            .catch(() => {
                toggleSubmitButton(btn);
            });
    });
})

function toggleSubmitButton(btn) {
    btn.disabled = !btn.disabled;
}

async function sendContactFormData(form) {
    const formIdPrefix = form.id;
    const widgetId = window.reCaptchaWidgets[form.querySelector('.captcha').id]

    const jsonData = {
        name: form.querySelector('#' + formIdPrefix + '-name').value.trim(),
        phone: form.querySelector('#' + formIdPrefix + '-phone').value.trim().length > 0 ? form.querySelector('#' + formIdPrefix + '-phone').value.trim() : '',
        email: form.querySelector('#' + formIdPrefix + '-email').value.trim(),
        textContent: form.querySelector('#' + formIdPrefix + '-message').value.trim(),
        allowEndorsement: form.querySelector('#' + formIdPrefix + '-endorsement').checked,
        captchaToken: window.grecaptcha.getResponse(widgetId)
    }

    try {
        const res = await postFormData('https://prod-efweb-backend.stjern.se/SendForm', jsonData)
        if (res.success) {
            form.reset();
            const el = form.querySelector('.ef-success');
            el.classList.remove('ef-snackbar--hidden');
            setTimeout(() => {
                el.classList.add('ef-snackbar--hidden');
            }, 5000)
        } else {
            const el = form.querySelector('.ef-failure');
            el.querySelector('.ef-contact-snackbar__error-message').innerText = generateErrorMessage(res.message);
            el.classList.remove('ef-snackbar--hidden');
        }
        window.grecaptcha.reset(widgetId);
    }
    catch (error) {
        form.reset();
        const el = form.querySelector('.ef-failure');
        el.querySelector('.ef-contact-snackbar__error-message').innerText = generateErrorMessage(error);
        el.classList.remove('ef-snackbar--hidden');
        window.grecaptcha.reset(widgetId);
        throw error;
    }
}

async function postFormData(url = '', data = {}) {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        if (response.status >= 400 && response.status <= 500) {
            throw new Error(response.statusText);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`Error: ${error}`)
    }
}


function validateForm(form) {
    const formIdPrefix = form.id;
    let isValid = true;
    const keys = Object.keys(form);
    for (let i = 0; i < keys.length; i++) {
        if (!isValid) {
            break;
        }
        if (form[i].id === `${formIdPrefix}-name`) {
            isValid = form[i].value.length > 0;
        }
        if (form[i].id === `${formIdPrefix}-email` && form[i].value.length > 0) {
            const regexp = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
            isValid = regexp.test(form[i].value);
        }
        if (form[i].id === `${formIdPrefix}-message`) {
            isValid = form[i].value.length > 0;
        }
        if (form[i].id === 'g-recaptcha-response') {
            isValid = form[i].value.length > 0;
        }
    }
    return isValid;
}

///////// RECAPTCHA HANDLING /////////////////////

window.recaptchaCallback = () => {
    document.querySelectorAll('.send-btn').forEach(btn => btn.disabled = false);
}

window.recaptchaExpiresCallback = () => {
    document.querySelectorAll('.send-btn').forEach(btn => btn.disabled = true);
}

window.onloadCallback = () => {

    const captchaOptions = {
        'sitekey': '6LcrBcsUAAAAACLTNgNTMMUzJ8ptnCszIVrUBr7t',
        'callback': recaptchaCallback,
        'expired-callback': recaptchaExpiresCallback
    }

    window.reCaptchaWidgets = {
        widget1: window.grecaptcha.render('widget1', captchaOptions),
        widget2: window.grecaptcha.render('widget2', captchaOptions)
    }
}

//////////////////////////////////